import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FirebaseOptionsToken, FirebaseNameOrConfigToken } from '@angular/fire';
import { credentials } from './credentials';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
//    AngularFireModule.initializeApp(testCredentials),
    AngularFirestoreModule,
    
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FirebaseOptionsToken, useValue: credentials.test, multi: true },
    { provide: FirebaseOptionsToken, useValue: credentials.one_point_one, multi: true },
    { provide: FirebaseOptionsToken, useValue: credentials.production, multi: true },
//    { provide: FirebaseNameOrConfigToken, useValue: 'test', multi: true },
//    { provide: FirebaseNameOrConfigToken, useValue: 'one_point_one', multi: true },
//    { provide: FirebaseNameOrConfigToken, useValue: 'production', multi: true },
  ],
  bootstrap: [AppComponent],


})
export class AppModule {}
