export const prodCredentials = {
  apiKey: "AIzaSyBe8H4kONASZZMQEd0hhcGbJoievjvBadY",
  authDomain: "edot-soulconnection.firebaseapp.com",
  databaseURL: "https://edot-soulconnection.firebaseio.com",
  projectId: "edot-soulconnection",
  storageBucket: "edot-soulconnection.appspot.com",
  messagingSenderId: "761790517993",
  appId: "1:761790517993:web:38e7d9f7e8baaa2ecb9678"
};

export const OnePointOneCredentials = {
  apiKey: 'AIzaSyCjKsAdDrB3Qx4NPxXFWgLJuPN7MUfQjgo',
  authDomain: 'edot-soulconnection-1-1.firebaseapp.com',
  databaseURL: 'https://edot-soulconnection-1-1.firebaseio.com',
  projectId: 'edot-soulconnection-1-1',
  storageBucket: '',
  messagingSenderId: '435101779773',
  appId: '1:435101779773:web:b0407fa993ff1d03'
};

export const testCredentials = {
  apiKey: "AIzaSyBrXO9HoNEAkbuvFAhjveE5tdL1-8Lb3a4",
  authDomain: "edot-soulconnection-test.firebaseapp.com",
  databaseURL: "https://edot-soulconnection-test.firebaseio.com",
  projectId: "edot-soulconnection-test",
  storageBucket: "edot-soulconnection-test.appspot.com",
  messagingSenderId: "507765288342",
  appId: "1:507765288342:web:5f8d6ee2d84049119cc1b7"
};

export const credentialArray = [
  { "Type": "production", "Credentials": prodCredentials },
  { "Type": "test", "Credentials": testCredentials },
  { "Type": "one_point_one", "Credentials": OnePointOneCredentials }
];

// Used for the token providers in App Module
export const credentials = {
  test: { testCredentials },
  production: { prodCredentials },
  one_point_one: { OnePointOneCredentials }
};

